import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment as env } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { AppChallengeFormComponent } from '../challenge/challenge-form.component';
import { MatDialog } from '@angular/material/dialog';
import { CategoryService } from 'src/app/services/category.service';
import { PropositionService, propositionStatus } from 'src/app/services/proposition.service';
import { Apollo } from 'apollo-angular';
import { GET_PROPOSITIONS } from 'src/app/graphql/proposition/queries';
import { map } from 'rxjs/operators';
import { GET_CATEGORIES } from 'src/app/graphql/category/queries';
import { Observable } from 'rxjs';

@Component({
  selector: 'challenge-propositions',
  templateUrl: './proposition.component.html',
})
export class AppPropositionComponent implements OnInit {

  private snackBar: MatSnackBar;

  constructor(private categoryService: CategoryService, private propositionService: PropositionService, snackBar: MatSnackBar, private http: HttpClient, private dialog: MatDialog) {
    this.snackBar = snackBar;
  }

  displayedColumns: string[] = [
    'id',
    'information',
    'category',
    'date',
    'status',
    'action',
  ];

  //status list
  statusList: any = Object.values(propositionStatus);

  dataSource: any;
  categoryDataSource: any;
  //get index ok firt element
  selectedStatus: string = propositionStatus.pending.value;

  ngOnInit() {
    this.getCategories();
    this.getPropositions();
  }

  getCategories() {
    this.categoryService.getCategories().subscribe((result: any) => {
      this.categoryDataSource = result.data.categories;
    });
  }

  getPropositions() {
    this.propositionService.getPropositions(this.selectedStatus).subscribe((result: any) => {
      this.dataSource = result.data.challengePropositions;
    });
  }

  updatePropositionStatus(id: string, status: string) {
     this.propositionService.processProposition(id, status)
      .subscribe({
        next: (result) => {
          //remove the proposition from the list
          this.dataSource = this.dataSource.filter((proposition: any) => proposition.id !== id);
          Swal.fire(
            'Success!',
            'Proposition status updated successfully!',
            'success'
          );
        },
        error: (error) => {
          Swal.fire(
            'Error!',
            error.error.message ?? error.error.errors[0],
            'error'
          );
        },
      });
  }

  transformChallenge(proposition: any) {
    this.dialog
      .open(AppChallengeFormComponent, {
        width: '50%',
        height: 'auto',
        data: {
          proposition: proposition,
          reason: 'transform',
        },
      })
      .afterClosed()
      .subscribe((result: any) => { });
  }

  filterPropositions(form: NgForm) {
    this.getPropositions();
  }

  //calculate the number of days between two dates
  calculateDays(startDate: string, endDate: string) {
    let date1: any = new Date(startDate);
    let date2: any = new Date(endDate);
    let diffDays: any = Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));
    return diffDays;
  }
}
