import { gql } from '@apollo/client/core';
import { CHALLENGE_FIELDS } from './fragments';

export const GET_CHALLENGES = gql`
    query getChallenges(
        \$categoryId: Int,
        \$createdBy: String,
        \$pagination: PaginationArgs,
        \$status: [ChallengeStatus!],
        \$userId: String
    ) {
        challenges(
        createdBy: \$createdBy,
        categoryId: \$categoryId,
        pagination: \$pagination,
        status: \$status, 
        userId: \$userId
        ) {
            items {
                ...challengeFields
            }
            pagination {
                hasMore
                total
            }
        }
    }
    ${CHALLENGE_FIELDS}
`;


export const GET_CHALLENGE_BY_ID = gql`
    query getChallengeById(\$id: Int!) {
        challenge(id: \$id) {
        ...challengeFields
        }
    }
    ${CHALLENGE_FIELDS}
`;

export const CHECK_UNICITY = gql`
    query checkUnicity(\$text: String!, \$type: UnicityType!) {
        checkUnicity(text: \$text, type: \$type)
    }
`;