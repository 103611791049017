import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportService, reportStatus, banDuration } from 'src/app/services/report.service';
import { AppReportComponent } from './report.component';
import Swal from 'sweetalert2';

export interface DialogData {
    typeOfElement: any,
    reason: any,
    elementId: any,
    user: any,
}

@Component({
    selector: 'warn-component',
    templateUrl: './warn.component.html',
})
export class AppWarnComponent implements OnInit {
    constructor(
        private reportService: ReportService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private snackBar: MatSnackBar, 
    ) {
        this.snackBar = snackBar;
    }

    reportDuration: any = Object.values(banDuration);
    reportReasons: any;
    blames: any;
    bans: any;

    ngOnInit(): void {
        this.getReportReasons();
        this.getBlames(this.data.user.uuid);
        this.getBans(this.data.user.uuid);
    }

    getReportReasons() {
        this.reportService.getReportReasons().subscribe((result: any) => {
            this.reportReasons = result.data.reportReasons;
        });
    }

    getBlames(userId: string) {
        this.reportService.getBlames(userId).subscribe((result: any) => {
            this.blames = result.data.blames;
        });
    }

    getBans(userId: string) {
        this.reportService.getBans(userId).subscribe((result: any) => {
            this.bans = result.data.bans;
        });
    }

    findReportReason(reasonKey: String) {
        return this.reportReasons.find((reason: any) => reason.key === reasonKey);
    }
    
    blockChallenge(challengeId: number, reason: string) {

        Swal.fire({
            heightAuto: false,
            title: 'Bloquer le challenge',
            text: 'Voulez-vous vraiment bloquer ce challenge et avertir son créateur ?',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler',
            confirmButtonColor: '#D8315B',
            cancelButtonColor: '#007bff',
        }).then((result) => {
            if (result.isConfirmed) {
                this.reportService.blockChallenge(challengeId, reason).subscribe((result: any) => {
                    this.snackBar.open('Challenge banni !', 'Close', {
                        duration: 2000,
                    });
                });
            }
        });
    }

    blockParticipation(participationId: number, reason: string) {
        Swal.fire({
            heightAuto: false,
            title: 'Bloquer la participation',
            text: 'Voulez-vous vraiment bloquer cette participation et avertir l\'utilisateur ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Annuler',
            confirmButtonColor: '#D8315B',
            cancelButtonColor: '#007bff',
        }).then((result) => {
            if (result.isConfirmed) {
                this.reportService.blockParticipation(participationId, reason).subscribe((result: any) => {
                    this.snackBar.open('Participation banni !', 'Close', {
                        duration: 2000,
                    });
                });
            }
        });
    }


}
