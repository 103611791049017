import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LoginService } from '../../../services/login.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() showToggle = true;
  @Input() toggleChecked = false;
  @Output() toggleMobileNav = new EventEmitter<void>();
  @Output() toggleMobileFilterNav = new EventEmitter<void>();
  @Output() toggleCollapsed = new EventEmitter<void>();

  showFiller = false;

  private LoginService: LoginService;
   session: any;

  constructor(public dialog: MatDialog, LoginService: LoginService) {
    this.LoginService = LoginService;
  }

  ngOnInit(): void {
    this.session = localStorage.getItem('user');
    if (this.session) {
      this.session = JSON.parse(this.session);
    }
  }

  logout() {
    this.LoginService.SignOut();
  }
  
}
