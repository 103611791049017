import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_SEARCH } from '../graphql/search/queries';

@Injectable({
    providedIn: 'root'
})
export class SearchService {
  
    constructor(private apollo: Apollo) { }
  
    search(query: string = '', pagination: any = {}) {
        return this.apollo.query({
            query: GET_SEARCH,
            variables: {
                query: query,
                pagination: pagination,
            },
        });
    }

}
