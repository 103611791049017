import { onError } from '@apollo/client/link/error';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { ApolloClientOptions, DefaultOptions, InMemoryCache, NetworkStatus } from '@apollo/client/core';
import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { environment as env } from '../environments/environment';
import { Router } from '@angular/router';

export function createApollo(httpLink: HttpLink, router: Router): ApolloClientOptions<any> {
  
  const http = httpLink.create({
    uri: env.graphqlUrl,
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    }),
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message.includes('Unauthorized')) {
          localStorage.clear();
          router.navigate(['/auth/login']);
        }
      });
    }

    if (networkError) {
      if (networkError.message.includes('Unauthorized')) {
        localStorage.clear();
        router.navigate(['/auth/login']);
      }
    }

  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  
  return {
    link: errorLink.concat(http),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Router]
    },
  ],
})
export class GraphQLModule { }
