<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->

<div class="row">
  <div class="col-lg-4">
    <mat-card class="cardWithShadow">
      <mat-card-content class="p-24">
        <mat-card-title *ngIf="editMode">Edit Admin</mat-card-title>
        <mat-card-title *ngIf="!editMode">Add New Admin</mat-card-title>

        <div class="p-24">

          <form class="m-t-30" #addAdmin = "ngForm" (ngSubmit)="editMode ? updateAdmin(addAdmin) : addNewAdmin(addAdmin)">
            
            <input matInput ngModel name ="adminId" hidden="true" />

            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input matInput ngModel name ="email" placeholder="exemple.xyz@site.com" required="true" email="true" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input matInput ngModel name ="firstname" placeholder="Nom" required="true" pattern="[a-zA-Z]*" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input matInput ngModel name ="lastname" placeholder="Prénom" required="true" pattern="[a-zA-Z]*" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="w-100" color="primary">
              <mat-label>Rôle</mat-label>
              <mat-select ngModel name ="role" required="true" >
                <mat-option *ngFor="let role of roles" [value]="role.role">
                  {{role.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Statut</mat-label>
              <mat-select ngModel name="active" required="true">
                <mat-option value = "true">Active</mat-option>
                <mat-option value = "false">Inactive</mat-option>
              </mat-select>
            </mat-form-field>
            
            <div class="row">
              <div class="col">
                <button mat-flat-button color="" class="w-100" *ngIf="editMode" (click)="cancelEdit()">
                  <mat-icon>close</mat-icon>
                  Cancel
                </button>
              </div>
              <div class="col">
                <button mat-flat-button color="primary" class="w-100">
                  <mat-icon>{{editMode ? 'check' : 'add'}}</mat-icon>
                  {{editMode ? 'Update' : 'Send invitation'}}
                </button>
              </div>
            </div>

          </form>

        </div>

      </mat-card-content>
    </mat-card>
  </div>

  <div class="col-lg-8">
    <mat-card class="cardWithShadow">
      <mat-card-content class="p-24">

        <div class="d-flex justify-content-between align-items-center m-b-16">
          <mat-card-title>BattleBox Admins List</mat-card-title>
          <mat-form-field appearance="outline" color="primary">
            <input matInput placeholder="Search" (keyup)="searchAdmin($event)" />
          </mat-form-field>
        </div>
        
        <div class="table-responsive m-t-16">
          <table mat-table [dataSource]="dataSource" class="w-100">
            <!-- Position Column -->
            <ng-container matColumnDef="user">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="f-w-600 mat-subtitle-1 f-s-14"
              >
                User
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <img
                    src="/assets/images/profile/user-1.jpg"
                    alt="users"
                    width="40"
                    class="rounded-circle"
                  />
                  <div class="m-l-16">
                    <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                      {{ element.firstname }} {{ element.lastname }}
                    </h6>
                    <span class="mat-body-1 f-s-12">
                      {{ element.email }}
                    </span>
                  </div>
                </div>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="active">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="f-w-600 mat-subtitle-1 f-s-14"
              >
                Account Status
              </th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                <ng-template [ngIf]="element.active">
                  <span
                    class="bg-light-success text-success rounded f-w-600 p-6 p-y-4 f-s-12"
                  >
                    Active
                  </span>
                </ng-template>
                <ng-template [ngIf]="!element.active">
                  <span
                    class="bg-light-error text-error rounded f-w-600 p-6 p-y-4 f-s-12"
                  >
                    Inactive
                  </span>
                </ng-template>
                
              </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="role">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="f-w-600 mat-subtitle-1 f-s-14"
              >
                Rôle
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-template [ngIf]="element.role">
                  <span
                    class="f-w-600 p-6 p-y-4 f-s-12"
                  >
                    {{ element.role.role }}
                  </span>
                </ng-template>
              </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="action">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="f-w-600 mat-subtitle-1 f-s-14"
              >
                Actions
              </th>
              <td mat-cell *matCellDef="let element" class="mat-body-1">
                <button mat-icon-button (click)="editAdmin(element)">
                  <mat-icon color="primary">edit</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
