import { Component, Input, OnInit } from '@angular/core';
import { AppChallengeComponent } from '../challenge/challenge.component';
import { AppReportComponent } from '../report/report.component';
import { challengeStatus } from 'src/app/services/challenge.service';

@Component({
  selector: 'participation-card',
  templateUrl: './participation-card.component.html',
  providers: [AppChallengeComponent, AppReportComponent],
})
export class ParticipationCardComponent implements OnInit {
  @Input() participation: any;

  statusList: any = Object.values(challengeStatus);
  
  constructor(
    public AppChallengeComponent: AppChallengeComponent,
    public AppReportComponent: AppReportComponent
  ) {
  }

  ngOnInit(): void { }
  
  findStatus(status: string) {
    return this.statusList.find((x: any) => x.value === status).label;
  }

}
