<mat-toolbar class="topbar bb-navbar-style">
  <!-- Mobile Menu -->
  <button mat-icon-button (click)="toggleMobileNav.emit()" style="background-color: antiquewhite; border: 3px white solid;" class="d-flex align-items-center">
    <i-tabler name="menu-2" class="icon-20 d-flex "></i-tabler>
  </button>
  <!-- --------------------------------------------------------------- -->

  <!-- --------------------------------------------------------------- -->
  <!-- Notification Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <!-- 
  <button mat-icon-button aria-label="Notifications">
    <i-tabler
      class="d-flex"
      name="bell"
      matBadge="1"
      matBadgeColor="primary"
    ></i-tabler>
  </button>
 -->
  <span class="flex-1-auto"></span>

  <!-- --------------------------------------------------------------- -->
  <!-- profile Dropdown -->
  <!-- --------------------------------------------------------------- -->
  <div style="background-color: antiquewhite;border-radius: 50px;border: 3px white solid" class="d-flex align-items-center" *ngIf="session"  [matMenuTriggerFor]="profilemenu">

    <span class="d-flex align-items-center"
      style="font-size: 15px; font-weight: 600; margin-left:20px;"
    >{{session.firstname}} {{session.lastname}}</span>

    <button mat-icon-button aria-label="Notifications">
      <img src="/assets/images/profile/user-1.jpg" class="rounded-circle object-cover" width="35" />
    </button>
    <mat-menu #profilemenu="matMenu" class="topbar-dd cardWithShadow">

      <!--     
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"
        ><i-tabler name="user" class="icon-18 d-flex"></i-tabler></mat-icon
      >My Profile
    </button>
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"
        ><i-tabler name="mail" class="icon-18 d-flex"></i-tabler></mat-icon
      >My Account
    </button>
    <button mat-menu-item>
      <mat-icon class="d-flex align-items-center"
        ><i-tabler
          name="list-check"
          class="icon-18 d-flex"
        ></i-tabler></mat-icon
      >My Task
    </button>
    -->
      <div class="p-x-12">
        <a mat-flat-button color="primary" class="d-flex justify-content-center" (click)="logout()">
          <mat-icon class="d-flex align-items-center"><i-tabler name="Logout"
              class="icon-18 d-flex"></i-tabler></mat-icon>
          <span class="d-flex align-items-center">Deconnexion</span>
        </a>

      </div>
    </mat-menu>

  </div>
</mat-toolbar>