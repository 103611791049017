import { gql } from '@apollo/client/core';

export const CATEGORY_BASE_FIELDS = gql`
    fragment categoryBaseFields on Category {
        id
        name
        icon
        color
        isActive
        description
    }
`;