<div class="col-12">
    <h2 mat-dialog-title align="center">Bannir l'utilisateur</h2>
    <mat-dialog-content class="mat-typography">
        <h6 class="mat-subtitle-1 f-s-14 f-w-600">
            <div class="d-flex align-items-center">
                <img [hidden]="!this.data.user.avatarUrl" [src]="this.data.user.avatarUrl" width="30" height="30"
                    class="rounded-circle" />
                <img [hidden]="this.data.user.avatarUrl" src="/assets/images/profile/user-1.jpg" width="30" height="30"
                    class="rounded-circle" />
                <div class="m-l-8">
                    <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                        {{ this.data.user.username }}
                    </h6>
                    <h6 class="mat-subtitle-2 f-s-10 f-w-600">
                        {{ this.data.user.rank.name }}
                    </h6>
                </div>
            </div>
        </h6>

        <mat-divider class="m-y-10"></mat-divider>

        <div class="d-flex justify-content-between flex-column">
            <h6 class="mat-subtitle-1 f-s-14 f-w-600 m-b-10">Liste des avertissements</h6>
            <div class="d-flex justify-content-start align-items-center" *ngFor="let blame of blames">
                <div class="d-flex flex-row">
                    <mat-icon class="text-warning m-r-10">warning</mat-icon>
                    <h6 class="mat-subtitle-1 f-s-14 f-w-600"> {{ findReportReason(blame.reason).name }}</h6>
                </div>
                <div>
                    <h6 class="mat-subtitle-1 f-s-14 f-w-600 m-l-8">({{ blame.date | date: 'dd/MM/yyyy' }})</h6>
                </div>
            </div>
            <h6 class="mat-subtitle-1 f-s-14 m-l-8" *ngIf="blames">Aucun avertissement</h6>
        </div>

        
        <mat-divider class="m-y-10"></mat-divider>

        <div class="d-flex justify-content-between flex-column">
            <h6 class="mat-subtitle-1 f-s-14 f-w-600 m-b-10">Liste des Bannissements</h6>
            <div class="d-flex justify-content-start align-items-center" *ngFor="let ban of bans">
                <div class="d-flex flex-row">
                    <mat-icon class="text-primary m-r-10">hardware</mat-icon>
                    <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                        <span class="bg-light-error text-error rounded f-w-600 p-6 p-y-4 f-s-12" *ngIf="ban.type === 'definitive'"> Définitif </span>
                        <span class="bg-light-warning text-warning rounded f-w-600 p-6 p-y-4 f-s-12" *ngIf="ban.type === 'temporary'"> Temporaire </span>
                        <span class="m-l-10">{{ findReportReason(ban.reason).name }}</span>
                    </h6>
                    <h6 class="mat-subtitle-1 f-s-14 f-w-600 m-l-8">
                        ({{ ban.startDate | date: 'dd/MM/yyyy' }}
                        <span *ngIf="ban.startDate && ban.endDate"> - </span>
                         {{ ban.endDate | date: 'dd/MM/yyyy' }})
                    </h6>
                </div>
            </div>
            <h6 class="mat-subtitle-1 f-s-14 m-l-8" *ngIf="bans">Aucun bannissement</h6>
        </div>

        <mat-divider class="m-y-10"></mat-divider>

        <form class="m-t-30" #banForm="ngForm">
            <div class="d-flex flex-column justify-content-between">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Nombre de jours</mat-label>
                    <mat-select matInput ngModel name="duration">
                        <mat-option *ngFor="let duration of reportDuration" [value]="duration.value">{{
                            duration.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Raison</mat-label>
                    <mat-select matInput ngModel name="reason" [(ngModel)]="this.data.reason">
                        <mat-option *ngFor="let reason of reportReasons" [value]="reason.key.toUpperCase()">{{
                            reason.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-button mat-dialog-close>Annuler</button>
        <button mat-flat-button color="primary" (click)="getFormData(banForm)"
            [disabled]="banForm.invalid"><mat-icon>hardware</mat-icon> Bannir l'utilisateur </button>
    </mat-dialog-actions>

</div>