import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_ADMINS, GET_ADMINS_ROLES } from '../graphql/admin/queries';
import { CREATE_ADMIN, UPDATE_ADMIN } from '../graphql/admin/mutations';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private apollo: Apollo) { }

    getAdmins() {
        return this.apollo.query({
            query: GET_ADMINS,
        });
    }

    getAvailableRoles() {
        return this.apollo.query({
            query: GET_ADMINS_ROLES
        });
    }

    addAdmin(email: string, firstname: string, lastname: string, role: string) {
        return this.apollo.mutate({
            mutation: CREATE_ADMIN,
            variables: {
                admin: {
                    email: email,
                    firstname: firstname,
                    lastname: lastname,
                    role: role
                }
            }
        });
    }

    updateAdmin(adminId: string, email?: string, firstname?: string, lastname?: string, role?: string, active?: boolean) {
        var variables: any  = {
            adminId: adminId
        };

        if (email !== undefined) {
            variables.email = email;
        }

        if (firstname !== undefined) {
            variables.firstname = firstname;
        }

        if (lastname !== undefined) {
            variables.lastname = lastname;
        }

        if (role !== undefined) {
            variables.role = role;
        }

        if (active !== undefined) {
            variables.active = active;
        }
        
        console.log(variables);

        return this.apollo.mutate({
            mutation: UPDATE_ADMIN,
            variables: {
                infos: variables
            }
        });
    }

}
