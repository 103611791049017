import { gql } from '@apollo/client/core';

export const PROCESS_PROPOSITION = gql`
  mutation processProposition($id: Int!, $status: String!) {
    processProposition(
      id: $id
      infos: {
        propositionStatus: $status
      }
    ) {
        id
    }
  }
`;