import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ADMIN_SIGNIN } from '../graphql/admin/mutations';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private apollo: Apollo) { }

  SignIn(email: string, password: string) {

    const body = {
      email: email,
      password: password,
    };

    return this.apollo.mutate({
      mutation: ADMIN_SIGNIN,
      variables: body,
      "errorPolicy": "ignore"
    });
  }

  SignOut() {
    localStorage.clear();
    window.location.href = '/auth/login';
  }

}
