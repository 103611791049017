import { gql } from '@apollo/client/core';
import { REPORT_FIELDS } from './fragments';

export const GET_REPORTS = gql`
    query getReports($status: String) {
        reports(status: $status) {
        ...reportFields
        }
    }
    ${REPORT_FIELDS}
`;

export const GET_REPORT_REASONS = gql`
    query getReportReasons {
        reportReasons {
            key
            name
        }
    }
`;

export const GET_BLAMES = gql`
    query getBlames ($userId: String) {
        blames (userId: $userId) {
            id
            date
            reason
        }
    }
`;

export const GET_BANS = gql`
    query getBans ($userId: String) {
        bans (userId: $userId) {
            id
            startDate
            endDate
            reason
            status
            type
        }
    }
`;