<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->

<div class="col-lg-12">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-24">
      <div class="d-flex justify-content-between align-items-center m-b-16">

        <div class="d-flex align-items-center">
          <h2 class="m-0 mat-headline-2 f-w-600">Propositions</h2>
          <span class="m-l-16 mat-body-1 f-s-12">List of propositions</span>
        </div>

      </div>

      <!-- select of challenge creator type -->

      <form class="d-flex" #filterChallengesForm = "ngForm" (ngSubmit)="filterPropositions(filterChallengesForm)">
        
        <mat-form-field appearance="outline" class="m-l-16" >
          <mat-label>Status</mat-label>
          <mat-select [(value)]="selectedStatus" matInput ngModel name ="status">
            
            <mat-option *ngFor= "let status of statusList" [value]="status.value">
              {{ status.label }}
            </mat-option>

          </mat-select>
        </mat-form-field>

        <button mat-icon-button type="submit" class="m-l-16">
          <mat-icon color="primary">search</mat-icon>
        </button>

      </form>

      <div class="table-responsive m-t-16">
        <table mat-table [dataSource]="dataSource" class="w-100">
          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="f-w-600 mat-subtitle-1 f-s-14"
            >
              Id
            </th>
            <td mat-cell *matCellDef="let element">
                <b class="">#{{ element.id }}</b>
            </td>
          </ng-container>

          <!-- Position Column -->
          <ng-container matColumnDef="information">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="f-w-600 mat-subtitle-1 f-s-14"
            >
            informations
            </th>
            <td mat-cell *matCellDef="let element" class="bb_media_description">
              <div class="d-flex align-items-center bb_media_description" *ngIf="element.user">
                
                <img *ngIf="element.user.avatarUrl != null && element.user.avatarUrl != ''"
                  [src]="element.user.avatarUrl"
                  width="40"
                  height="40"
                  class="rounded-circle"
                />
                <img *ngIf="element.user.avatarUrl == null || element.user.avatarUrl == ''"
                  src="/assets/images/profile/user-1.jpg"
                  width="40"
                  height="40"
                  class="rounded-circle"
                />
                <div class="m-l-16">
                  <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                    {{ element.user.username }}
                  </h6>
                  <span class="mat-body-1 f-s-12">
                    {{ element.description }}
                  </span>
                </div>
              </div>

              <div class="d-flex align-items-center bb_media_description" *ngIf="!element.user">
                <img
                  src="/assets/images/profile/user-1.jpg"
                  width="40"
                  height="40"
                  class="rounded-circle"
                />
                <div class="m-l-16">
                  <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                    Anonymous
                  </h6>
                  <span class="mat-body-1">
                    {{ element.description }}
                  </span>
                </div>
              </div>

            </td>
          </ng-container>
          
          <ng-container matColumnDef="category">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="f-w-600 mat-subtitle-1 f-s-14 text-center"
            >
              Category
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.category" class="text-center">
                
                <div class="d-flex align-items-center justify-content-center">
                  <div class="m-l-16">
                    <h6 class="mat-subtitle-1 f-s-20">
                      {{ element.category.icon }}
                    </h6>
                    <span class="mat-body-1">
                      {{ element.category.name }}
                    </span>
                  </div>
                </div>

              </div>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="date">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="f-w-600 mat-subtitle-1 f-s-14"
            >
              Date
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-template [ngIf]="element.date">
                
                <div class="d-flex align-items-center">
                  <div class="m-l-16">
                    <span
                      class="rounded f-w-600 p-6 p-y-4 f-s-12"
                    >
                      {{ element.date | date: 'dd/MM/yyyy HH:mm' }}
                    </span>
                  </div>
                </div>
              </ng-template>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="status">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="f-w-600 mat-subtitle-1 f-s-14"
            >
              Status
            </th>
            <td mat-cell *matCellDef="let element" class="mat-body-1">
              
              <ng-template [ngIf]="element.status">
                
                <div class="d-flex align-items-center">
                  <div class="m-l-16">
                    <span class="rounded f-w-600 p-6 p-y-4 f-s-12" 
                      [ngClass]="{
                        'text-success bg-light-success': element.status == 'accepted', 
                        'text-primary bg-light-primary': element.status == 'rejected',
                        'text-accent bg-light-accent': element.status == 'closed',
                        'text-warning bg-light-warning': element.status == 'pending',
                      }">
                      {{ element.status }}
                    </span>
                  </div>
                </div>

              </ng-template>

            </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="action">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="f-w-600 mat-subtitle-1 f-s-14"
            >
              Actions
            </th>
            <td mat-cell *matCellDef="let element" class="mat-body-1">
              
              <button mat-icon-button (click)="transformChallenge(element)"  class="text-success" *ngIf="element.status == 'accepted'">
                <mat-icon>create</mat-icon>
              </button>

              <button mat-icon-button (click)="updatePropositionStatus(element.id, 'accepted')" *ngIf="element.status != 'accepted' && element.status != 'closed' && element.status != 'rejected'">
                <mat-icon class="text-success">check</mat-icon>
              </button>

              <button mat-icon-button (click)="updatePropositionStatus(element.id, 'rejected')" *ngIf="element.status != 'rejected' && element.status != 'closed'">
                <mat-icon color="primary">close</mat-icon>
              </button>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>

