import { gql } from '@apollo/client/core';
import { CHALLENGE_BASE_FIELDS } from '../challenge/fragments';
import { USER_BASE_FIELDS } from '../user/fragments';

export const GET_SEARCH = gql`
    query search ($query: String!, $pagination: PaginationArgs) {
        search (query: $query, pagination: $pagination) {
            items {
                ... on Challenge {
                    ...challengeBaseFields
                    creator {
                        uuid
                        username
                        avatarUrl
                        rank {
                            id
                            name
                            label
                        }
                    }
                }
                ... on User {
                    ...userBaseFields
                }
                ... on Participation {
                    description
                    fileUrl
                    id
                    nbComments
                    nbLikes
                    nbShares
                    hasLiked
                    hasShared
                    publicationDate
                    participationStatus : status
                    thumbnailUrl
                    boomerangUrl
                    views
                    user {
                      ...userBaseFields
                    }
                }
            }
            pagination {
                hasMore
                total
            }
        }
    }
    ${USER_BASE_FIELDS}
    ${CHALLENGE_BASE_FIELDS}
`;
