import { gql } from '@apollo/client/core';

export const ADMIN_BASE_FIELDS = gql`
    fragment adminBaseFields on Admin {
        uuid
        firstname
        lastname
        email
        active
        role {
            label
            role
        }
    }
`;

/* 
        #creationdate 
        #role {
            #role
            #label
            #}
            */