import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    constructor() { }
    //transform sql date 2023-10-19T10:22:00.000Z to angular date iso utc
    transformSqlDate(sqlDate: string): string {
        // Parse the input date string to a Date object
        const date = new Date(sqlDate);

        // Extract and format the date components
        const year = date.getUTCFullYear().toString(); // Get full year
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getUTCDate().toString().padStart(2, '0'); // Get day of month
        const hours = date.getUTCHours().toString().padStart(2, '0'); // Get hour (24-hour clock)
        const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Get minutes
        const seconds = date.getUTCSeconds().toString().padStart(2, '0'); // Get seconds

        // Construct the output string in the desired format and append "Z" for UTC
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    }

    displayDateForForm(date: string): string {
        // Parse the input date string to a Date object
        const dateObj = new Date(date);

        // Extract and format the date components
        const year = dateObj.getFullYear().toString(); // Get full year
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = dateObj.getDate().toString().padStart(2, '0'); // Get day of month
        const hours = dateObj.getHours().toString().padStart(2, '0'); // Get hour (24-hour clock)
        const minutes = dateObj.getMinutes().toString().padStart(2, '0'); // Get minutes

        // Construct the output string in the desired format
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

}
