import { gql } from '@apollo/client/core';
import { CATEGORY_BASE_FIELDS } from '../category/fragments';

export const CHALLENGE_BASE_FIELDS = gql`
    fragment challengeBaseFields on Challenge {
        description
        earnableTrophies
        endDate
        id
        name
        nbComments
        nbLikes
        nbParticipations
        nbShares
        startDate
        status
        tag
        videoUrl
        thumbnailUrl
        boomerangUrl
        type
        views
        category {
        ...categoryBaseFields
        }
    }
    ${CATEGORY_BASE_FIELDS}
`;

export const CHALLENGE_FIELDS = gql`
    fragment challengeFields on Challenge {
        ...challengeBaseFields
        creator {
            uuid
            username
            avatarUrl
            rank {
                id
                name
                label
            }
        }
        proposedBy {
            uuid
            username
            avatarUrl
        }
    }
    ${CHALLENGE_BASE_FIELDS}
`;