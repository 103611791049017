import { gql } from '@apollo/client/core';
import { USER_BASE_FIELDS } from '../user/fragments';
import { CHALLENGE_BASE_FIELDS } from '../challenge/fragments';

export const PARTICIPATION_BASE_FIELDS = gql`
    fragment participationBaseFields on Participation {
        description
        fileUrl
        id
        nbComments
        nbLikes
        nbShares
        hasLiked
        hasShared
        publicationDate
        status
        thumbnailUrl
        boomerangUrl
        views
        user {
          ...userBaseFields
        }
        challenge {
          ...challengeBaseFields
        }
    }
    ${USER_BASE_FIELDS}
    ${CHALLENGE_BASE_FIELDS}
`;

export const PARTICIPATION_FIELDS = gql`
    fragment participationFields on PaginatedParticipations {
        items {
            ...participationBaseFields
        }
        pagination {
            hasMore
            total
        }
    }
    ${PARTICIPATION_BASE_FIELDS}
`;
