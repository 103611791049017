import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html'
})
export class AppManagementComponent implements OnInit {

  constructor(private http: HttpClient, private snackBar: MatSnackBar, private adminService: AdminService) { }

  displayedColumns: string[] = [
    'user',
    'active',
    'role',
    //'date',
    'action'
  ];
  dataSource: any;
  dataSourceTemp: any;
  roles: any;

  editMode = false;
  @ViewChild('addAdmin') public adminForm: NgForm;

  ngOnInit(): void {
    this.getRoles();
    this.getAdmins();
  }

  getAdmins() {
    this.adminService.getAdmins().subscribe((result: any) => {
      this.dataSource = result.data.admins;
      this.dataSourceTemp = this.dataSource;
    });
  }

  getRoles() {
    this.adminService.getAvailableRoles().subscribe((result: any) => {
      this.roles = result.data.availableRoles;
    });
  }

  addNewAdmin(form: NgForm) {

    if (!form.value.email || !form.value.firstname || !form.value.lastname || !form.value.role) {
      this.snackBar.open('Champs obligatoire !', 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }

    this.adminService.addAdmin(form.value.email, form.value.firstname, form.value.lastname, form.value.role)
      .subscribe({
        next: result => {
          this.getAdmins();
          this.snackBar.open(`Admin ${form.value.email} added !`, 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          form.resetForm();
        },
        error: error => {
          console.log(error);
          this.snackBar.open(error.error.errors[0], 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });

  }

  updateAdmin(form: NgForm) {

    if (!form.value.email || !form.value.firstname || !form.value.lastname || !form.value.role) {
      this.snackBar.open('Champs obligatoire !', 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }

    this.adminService.updateAdmin(form.value.adminId, form.value.email, form.value.firstname, form.value.lastname, form.value.role, JSON.parse(form.value.active))
      .subscribe({
        next: result => {
          this.getAdmins();
          this.snackBar.open('Admin '+form.value.email+' updated !', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
          form.resetForm();
          this.editMode = false;
        },
        error: error => {
          console.log(error);
          this.snackBar.open("An error occured", 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  editAdmin(admin: any) {
    this.editMode = true;
    this.adminForm.setValue({
      adminId: admin.uuid ? admin.uuid : '',
      email: admin.email ? admin.email : '',
      firstname: admin.firstname ? admin.firstname : '',
      lastname: admin.lastname ? admin.lastname : '',
      role: admin.role.label ? admin.role.label : '',
      active: admin.active ? admin.active.toString() : "false"
    });
  }

  cancelEdit() {
    this.editMode = false;
    this.adminForm.resetForm();
  }

  searchAdmin(event: any) {
    const filterValue = event.target.value;
    console.log(filterValue);
    //filtre le tableau de données
    if (filterValue) {
      this.dataSource = this.dataSource.filter((admin: any) => {
        return admin.email.toLowerCase().includes(filterValue.toLowerCase()) || admin.firstname.toLowerCase().includes(filterValue.toLowerCase()) || admin.lastname.toLowerCase().includes(filterValue.toLowerCase());
      });
    } else {
      this.dataSource = this.dataSourceTemp;
    }
  }

}