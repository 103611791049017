import { gql } from '@apollo/client/core';
import { ADMIN_BASE_FIELDS } from './fragments';

export const GET_ADMINS = gql`
    query admins {
        admins {
            ...adminBaseFields
        }
    }
    ${ADMIN_BASE_FIELDS}
`;

export const GET_ADMINS_ROLES = gql`
    query availableRoles {
        availableRoles {
            label
            role
        }
    }
`;