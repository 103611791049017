<mat-card class="cardWithShadow theme-card">
    <mat-card-header>
        <mat-card-title class="m-b-0">Recherche</mat-card-title>
    </mat-card-header>
    <mat-card-content class="b-t-1">
        <div class="d-flex justify-content-center align-items-center">
            <mat-form-field appearance="outline" color="primary" class="w-100">
                <input matInput placeholder="Que recherchez-vous ?" (keyup)="getFormValue($event)" />
            </mat-form-field>
        </div>

    </mat-card-content>
</mat-card>

<div *ngIf="searchResults && searchResults.length > 0">

    <div *ngFor="let result of searchResults">

        <user-card *ngIf="result.__typename === 'User'" [user]="result"></user-card>

        <challenge-card *ngIf="result.__typename === 'Challenge'" [challenge]="result"></challenge-card>

        <participation-card *ngIf="result.__typename === 'Participation'" [participation]="result"></participation-card>

    </div>

</div>

<div *ngIf="!searchResults || searchResults.length === 0">
    <p class="text-center">Aucun résultat trouvé</p>
</div>