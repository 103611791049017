import { Component, Input, OnInit } from '@angular/core';
import { AppBanComponent } from '../report/ban.component';
import { AppReportComponent } from '../report/report.component';
import Swal from 'sweetalert2';
import { ReportService, banDuration, reportStatus } from 'src/app/services/report.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'user-card',
	templateUrl: './user-card.component.html',
})
export class UserCardComponent implements OnInit {
	@Input() user: any;

	constructor(
		private reportService: ReportService,
		public dialog: MatDialog,
		private snackBar: MatSnackBar,
	) {
		this.snackBar = snackBar;
	}

	//status list
	statusList: any = Object.values(reportStatus);
	reportDuration: any = Object.values(banDuration);

	ngOnInit(): void {
	}

	banUser(userId: String, reason: string, duration: string) {

		let startDate = new Date().toISOString();
		let endDate = new Date();

		let endDateText;
		if (duration === '0' || duration === '') {
			endDateText = undefined;
		} else {
			endDate.setDate(endDate.getDate() + parseInt(duration));
			endDateText = endDate.toISOString();
		}

		this.reportService.banUser(userId, reason, startDate, endDateText).subscribe((result: any) => {
			this.snackBar.open(this.user.username + ' banni pour ' + this.reportDuration.find((x: any) => x.value === duration).label + ' ', 'Close', {
				duration: 2000,
			});
		});
	}

	unbanUser(userId: string) {
		Swal.fire({
			heightAuto: false,
			title: 'Débloquer l\'utilisateur',
			text: 'Voulez-vous vraiment débloquer cet utilisateur ?',
			showCancelButton: true,
			confirmButtonText: 'Oui',
			cancelButtonText: 'Annuler',
			confirmButtonColor: '#D8315B',
			cancelButtonColor: '#007bff',
		}).then((result) => {
			if (result.isConfirmed) {
				this.reportService.unbanUser(userId).subscribe((result: any) => {
					this.snackBar.open('Utilisateur débloqué !', 'Close', {
						duration: 2000,
					});
				});
			}
		});
	}

	banUserPopup(user: any, reason: any = "") {
		this.dialog
			.open(AppBanComponent, {
				width: '30%',
				data: {
					reason: reason,
					user: user
				},
			})
			.afterClosed()
			.subscribe((result: any) => {
			});
	}

}
