import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportService, banDuration } from 'src/app/services/report.service';
import Swal from 'sweetalert2';

export interface DialogData {
    reason: any;
    user: any;
}

@Component({
    selector: 'ban-component',
    templateUrl: './ban.component.html',
})
export class AppBanComponent implements OnInit {

    constructor(
        public snackBar: MatSnackBar,
        private reportService: ReportService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        this.snackBar = snackBar;
    }

    reportDuration: any = Object.values(banDuration);
    reportReasons: any;
    blames: any;
    bans: any;

    ngOnInit(): void {
        this.getReportReasons();
        this.getBlames(this.data.user.uuid);
        this.getBans(this.data.user.uuid);
    }

    getReportReasons() {
        this.reportService.getReportReasons().subscribe((result: any) => {
            this.reportReasons = result.data.reportReasons;
        });
    }

    getFormData(from: NgForm) {
        this.banUser(this.data.user.uuid, from.value.reason, from.value.duration);
    }

    banUser(userId: String, reason: string, duration: string) {

        let startDate = new Date().toISOString();
        let endDate = new Date();

        let endDateText;
        if (duration === '0' || duration === '') {
            endDateText = undefined;
        } else {
            endDate.setDate(endDate.getDate() + parseInt(duration));
            endDateText = endDate.toISOString();
        }

        this.reportService.banUser(userId, reason, startDate, endDateText).subscribe((result: any) => {
            this.snackBar.open(this.data.user.username + ' banni pour ' + this.reportDuration.find((x: any) => x.value === duration).label + ' ', 'Close', {
                duration: 2000,
            });
        });
    }

    unbanUser(userId: string) {
        Swal.fire({
            heightAuto: false,
            title: 'Débloquer l\'utilisateur',
            text: 'Voulez-vous vraiment débloquer cet utilisateur ?',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler',
            confirmButtonColor: '#D8315B',
            cancelButtonColor: '#007bff',
        }).then((result) => {
            if (result.isConfirmed) {
                this.reportService.unbanUser(userId).subscribe((result: any) => {
                    this.snackBar.open('Utilisateur débloqué !', 'Close', {
                        duration: 2000,
                    });
                });
            }
        });
    }

    getBlames(userId: string) {
        this.reportService.getBlames(userId).subscribe((result: any) => {
            this.blames = result.data.blames;
        });
    }

    getBans(userId: string) {
        this.reportService.getBans(userId).subscribe((result: any) => {
            this.bans = result.data.bans;
        });
    }

    findReportReason(reasonKey: String) {
        return this.reportReasons.find((reason: any) => reason.key === reasonKey);
    }

}
