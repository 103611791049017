import { gql } from '@apollo/client/core';
import { CATEGORY_BASE_FIELDS } from './fragments';

export const GET_CATEGORIES = gql`
    query getCategories {
        categories {
        ...categoryBaseFields
        }
    }
    ${CATEGORY_BASE_FIELDS}
`;

export const GET_CATEGORY_BY_ID = gql`
    query getCategoryById($id: Int!) {
        category(id: $id) {
        ...categoryBaseFields
        }
    }
    ${CATEGORY_BASE_FIELDS}
`;