<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->

<div class="col-lg-12">
    <mat-card class="cardWithShadow">
        <mat-card-content class="p-24">
            <div class="d-flex justify-content-between align-items-center m-b-16">

                <div class="d-flex align-items-center">
                    <h2 class="m-0 mat-headline-2 f-w-600">Signalements</h2>
                    <span class="m-l-16 mat-body-1 f-s-12">Liste des signalements</span>
                </div>

            </div>

            <!-- select of challenge creator type -->

            <form class="d-flex" #filterChallengesForm="ngForm">

                <mat-form-field appearance="outline" class="m-l-16">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="selectedStatus" matInput ngModel name="status" (ngModelChange)="filterReports($event)">

                        <mat-option *ngFor="let status of statusList" [value]="status.value">
                            {{ status.label }}
                        </mat-option>

                    </mat-select>
                </mat-form-field>

                <!--<button mat-icon-button type="submit" class="m-l-16">
                    <mat-icon color="primary">search</mat-icon>
                </button>-->

            </form>

            <div class="table-responsive m-t-16">
                <table mat-table [dataSource]="reportDataSource" class="w-100">
                    <!-- Position Column -->
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
                            Id
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <b class="">#{{ element.id }}</b>
                        </td>
                    </ng-container>

                    <!-- information -->
                    <ng-container matColumnDef="information">
                        <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
                            informations
                        </th>
                        <td mat-cell *matCellDef="let element" class="bb_media_description">
                            <div class="d-flex align-items-start p-6 bb_media_description">

                                <!-- reportedChallenge -->
                                <div class="d-flex align-items-start bb_media_description"
                                    *ngIf="typeOfReport(element) === 'challenge'">

                                    <div class="d-flex flex-column"
                                        (click)="this.AppChallengeComponent.fullScreenMedia(element.reportedChallenge.videoUrl)">
                                        <video *ngIf="element.reportedChallenge.boomerangUrl"
                                            [src]="element.reportedChallenge.boomerangUrl" width="80" class="rounded"
                                            loop autoplay>
                                        </video>
                                        <img *ngIf="element.reportedChallenge.thumbnailUrl && !element.reportedChallenge.boomerangUrl"
                                            [src]="element.reportedChallenge.thumbnailUrl" width="80" class="rounded" />
                                    </div>

                                    <div class="m-l-16">
                                        <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                                            Challenge
                                        </h6>
                                        <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                            <div class="d-flex align-items-center">
                                                <img [hidden]="!element.reportedChallenge.creator.avatarUrl"
                                                    [src]="element.reportedChallenge.creator.avatarUrl" width="30"
                                                    height="30" class="rounded-circle" />
                                                <img [hidden]="element.reportedChallenge.creator.avatarUrl"
                                                    src="/assets/images/profile/user-1.jpg" width="30" height="30"
                                                    class="rounded-circle" />
                                                <div class="m-l-8">
                                                    <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                                        {{ element.reportedChallenge.creator.username }}
                                                    </h6>
                                                    <h6 class="mat-subtitle-2 f-s-10 f-w-600">
                                                        {{ element.reportedChallenge.creator.rank.name }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </h6>
                                        <p class="m-t-16">{{element.reportedChallenge.description }}</p>
                                    </div>

                                </div>

                                <!-- reportedParticipation -->
                                <div class="d-flex align-items-start bb_media_description"
                                    *ngIf="typeOfReport(element) === 'participation'">

                                    <div class="d-flex flex-column"
                                        (click)="this.AppChallengeComponent.fullScreenMedia(element.reportedParticipation.fileUrl)">
                                        <video *ngIf="element.reportedParticipation.boomerangUrl"
                                            [src]="element.reportedParticipation.boomerangUrl" width="80"
                                            class="rounded" loop autoplay>
                                        </video>
                                        <img *ngIf="element.reportedParticipation.thumbnailUrl && !element.reportedParticipation.boomerangUrl"
                                            [src]="element.reportedParticipation.thumbnailUrl" width="80"
                                            class="rounded" />
                                    </div>

                                    <div class="m-l-16">
                                        <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                                            Participation
                                        </h6>
                                        <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                            <div class="d-flex align-items-center">
                                                <img [hidden]="!element.reportedParticipation.user.avatarUrl"
                                                    [src]="element.reportedParticipation.user.avatarUrl" width="30"
                                                    height="30" class="rounded-circle" />
                                                <img [hidden]="element.reportedParticipation.user.avatarUrl"
                                                    src="/assets/images/profile/user-1.jpg" width="30" height="30"
                                                    class="rounded-circle" />
                                                <div class="m-l-8">
                                                    <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                                        {{ element.reportedParticipation.user.username }}
                                                    </h6>
                                                    <h6 class="mat-subtitle-2 f-s-10 f-w-600">
                                                        {{ element.reportedParticipation.user.rank.name }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </h6>
                                        <p class="m-t-16">{{ element.reportedParticipation.description }}</p>

                                    </div>

                                </div>

                                <!-- reportedUser -->

                                <div class="d-flex align-items-start bb_media_description"
                                    *ngIf="typeOfReport(element) === 'user'">

                                    <div class="m-l-16 p-x-10">
                                        <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary ">
                                            Utilisateur
                                        </h6>
                                        <div class="d-flex flex-column">
                                            <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                                <div class="d-flex align-items-center">
                                                    <img [hidden]="!element.reportedUser.avatarUrl"
                                                        [src]="element.reportedUser.avatarUrl" width="50"
                                                        height="50" class="rounded-circle" />
                                                    <img [hidden]="element.reportedUser.avatarUrl"
                                                        src="/assets/images/profile/user-1.jpg" width="30" height="30"
                                                        class="rounded-circle" />
                                                    <div class="m-l-8">
                                                        <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                                            {{ element.reportedUser.username }}
                                                        </h6>
                                                        <h6 class="mat-subtitle-2 f-s-10 f-w-600">
                                                            {{ element.reportedUser.rank.name }}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </h6>
                                        </div>
    
                                    </div>
                                </div>

                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="reporter">
                        <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
                            Information du signalement
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-body-1">

                            <!-- reported User info -->
                            <div class="m-l-16">
                                <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                    <div class="d-flex align-items-center">
                                        <img [hidden]="!element.reporter.avatarUrl" [src]="element.reporter.avatarUrl"
                                            width="30" height="30" class="rounded-circle" />
                                        <img [hidden]="element.reporter.avatarUrl"
                                            src="/assets/images/profile/user-1.jpg" width="30" height="30"
                                            class="rounded-circle" />
                                        <div class="m-l-8">
                                            <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                                                {{ element.reporter.username }}
                                            </h6>
                                            <h6 class="mat-subtitle-2 f-s-10 f-w-600">
                                                {{ element.reporter.rank.name }}
                                            </h6>
                                        </div>
                                    </div>
                                </h6>
                                <hr class="m-y-8" style="border-top: 1px solid #f5f5f5;">
                                <p class="m-0 f-w-600 m-b-16">{{findReportReason(element.reason.toLowerCase()).name}}
                                </p>
                                <p class="m-0">{{ element.context ?? 'Aucun contexte' }}</p>
                            </div>

                        </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="f-w-600 mat-subtitle-1 f-s-14">
                            Actions
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-body-1">

                            <div class="d-flex flex-column align-items-center">
                                
                                <!-- close report -->
                                <button mat-flat-button color="accent" class="w-100 m-b-10" (click)="closeReport(element)">
                                    <mat-icon>check</mat-icon> Tout vas bien !
                                </button>

                                <!-- ban User -->
                                <div class="d-flex flex-row w-100" *ngIf="typeOfReport(element) === 'user'">
                                    <button mat-flat-button color="primary" class="w-100 m-b-10" (click)="banUserPopup(this.getUserToban(element), element.reason)"> 
                                        <mat-icon>hardware</mat-icon> Bannir l'utilisateur
                                    </button>
                                </div>

                                <!-- block participation -->
                                <div class="d-flex flex-row w-100" *ngIf="typeOfReport(element) === 'participation'">
                                    <button mat-flat-button color="warn" class="w-100 m-b-10" (click)="warnUserPopup('participation', element.reportedParticipation.id, this.getUserToban(element),element.reason)">
                                        <mat-icon>warning</mat-icon> Avertir l'utilisateur
                                    </button>

                                    <div class="p-6"></div>

                                    <button mat-flat-button color="primary" class="w-100 m-b-10" (click)="banUserPopup(this.getUserToban(element), element.reason)"> 
                                        <mat-icon>hardware</mat-icon> Bannir l'utilisateur
                                    </button>
                                </div>

                                <!-- block challenge -->
                                <div class="d-flex flex-row w-100" *ngIf="typeOfReport(element) === 'challenge'">
                                    <button mat-flat-button color="warn" class="w-100 m-b-10" (click)="warnUserPopup('challenge', element.reportedChallenge.id, this.getUserToban(element),element.reason)">
                                        <mat-icon>warning</mat-icon> Avertir l'utilisateur
                                    </button>

                                    <div class="p-6"></div>

                                    <button mat-flat-button color="primary" class="w-100 m-b-10" (click)="banUserPopup(this.getUserToban(element), element.reason)"> 
                                        <mat-icon>hardware</mat-icon> Bannir l'utilisateur
                                    </button>
                                </div>
                                

                            </div>

                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </mat-card-content>
    </mat-card>
</div>