<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->
<mat-card class="cardWithShadow col">
  <mat-card-content class="p-10 m-10">

    <div class="d-flex flex-row justify-content-between">

      <div class="d-flex align-items-start">

        <div class="d-flex flex-column" (click)="this.AppChallengeComponent.fullScreenMedia(participation.fileUrl)">
          <div class="f-s-16 f-w-600 m-b-10">
            ID: {{ participation.id }}
          </div>
          <video *ngIf="participation.boomerangUrl" [src]="participation.boomerangUrl" width="120" class="rounded" loop
            autoplay>
          </video>
          <img *ngIf="participation.thumbnailUrl && !participation.boomerangUrl" [src]="participation.thumbnailUrl"
            width="120" class="rounded" />
        </div>

        <div class="m-l-16">
          <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
            Participation
          </h6>
          <h6 class="mat-subtitle-1 f-s-14 f-w-600">
            <div class="d-flex align-items-center">
              <img [hidden]="!participation.user.avatarUrl" [src]="participation.user.avatarUrl" width="30" height="30"
                class="rounded-circle" />
              <img [hidden]="participation.user.avatarUrl" src="/assets/images/profile/user-1.jpg" width="30"
                height="30" class="rounded-circle" />
              <div class="m-l-8">
                <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                  {{ participation.user.username }}
                </h6>
                <h6 class="mat-subtitle-2 f-s-10 f-w-600">
                  {{ participation.user.rank.name }}
                </h6>
              </div>
            </div>
          </h6>
          <p class="m-t-16">{{ participation.description }}</p>

          <div class="d-flex align-items-center m-t-16 rounded bg-grey">
            <span class="mat-body-1 f-s-12 f-w-600">
              Statut: 
            </span>
            <span class="mat-body-1 f-s-12">
              {{ findStatus(participation.participationStatus) }}
            </span>
          </div>

          <mat-divider class="m-y-8"></mat-divider>

          <h6 class="mat-subtitle-1 f-s-14 f-w-600">
            Statistiques :
          </h6>

          <div class="text-center rounded">
            <div class="d-flex">
              <div class="d-flex flex-column align-items-center m-10">
                <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                  {{ participation.views | number }}
                </h6>
                <span class="mat-body-1 f-s-12 f-w-600">
                  Vues
                </span>
              </div>
              <div class="d-flex flex-column align-items-center m-10">
                <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                  {{ participation.nbLikes | number }}
                </h6>
                <span class="mat-body-1 f-s-12 f-w-600">
                  Flammes
                </span>
              </div>
              <div class="d-flex flex-column align-items-center m-10">
                <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                  {{ participation.nbComments | number }}
                </h6>
                <span class="mat-body-1 f-s-12 f-w-600">
                  Commentaires
                </span>
              </div>
              <div class="d-flex flex-column align-items-center m-10">
                <h6 class="mat-subtitle-1 f-s-14 f-w-600 text-primary">
                  {{ participation.nbShares | number }}
                </h6>
                <span class="mat-body-1 f-s-12 f-w-600">
                  Partages
                </span>
              </div>
            </div>
          </div>

        </div>

      </div>

      <!-- block participation -->
      <div class="d-flex flex-column align-items-center justify-content-center ">

        <button mat-flat-button color="warn" class="w-100 m-b-10"
          (click)="this.AppReportComponent.warnUserPopup('participation', participation.id, participation.user)">
          <mat-icon>warning</mat-icon> Avertir l'utilisateur
        </button>

        <div class="p-6"></div>

        <button mat-flat-button color="primary" class="w-100 m-b-10"
          (click)="this.AppReportComponent.banUserPopup(participation.user)">
          <mat-icon>hardware</mat-icon> Bannir l'utilisateur
        </button>
      </div>

    </div>

  </mat-card-content>
</mat-card>