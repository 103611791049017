import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/services/search.service';

@Component({
    selector: 'search-component',
    templateUrl: './search.component.html',
})
export class AppSearchComponent implements OnInit {

    constructor(private searchService: SearchService) { }

    query: string = '';
    searchResults: any = [];

    offset: number = 0;
    count: number = 10;
    hasMore: boolean = false;

    ngOnInit(): void { }

    loadMore() {
        console.log('load more');
        this.offset += this.count;
        this.search(this.query);
    }

    getFormValue(event: any) {
        this.query = event.target.value;

        if (this.query.length < 4) {
            this.searchResults = [];
            return;
        }

        this.search(this.query);
    }

    search(query: string) {
        this.searchService.search(query, {
            count: this.count,
            offset: this.offset,
        }).subscribe((result: any) => {
            console.log(result);
            this.searchResults = result.data.search.items;
            this.hasMore = result.data.search.pagination.hasMore;
        });
    }

}