import { gql } from '@apollo/client/core';
import { PROPOSITION_BASE_FIELDS } from './fragments';

export const GET_PROPOSITIONS = gql`
    query challengePropositions (\$userId: String, \$status: String) {
        challengePropositions(userId: \$userId, status: \$status) {
            ...PropositionFields
        }
    }
    ${PROPOSITION_BASE_FIELDS}
`;
