import { gql } from '@apollo/client/core';
import { CATEGORY_BASE_FIELDS } from '../category/fragments';
import { CHALLENGE_BASE_FIELDS } from '../challenge/fragments';

export const PROPOSITION_BASE_FIELDS = gql`
    fragment PropositionFields on ChallengeProposition {
        id
        description
        status
        date
        associatedChallenge {
        ...challengeBaseFields
        }
        category {
        ...categoryBaseFields
        }
    }
    ${CATEGORY_BASE_FIELDS}
    ${CHALLENGE_BASE_FIELDS}
`;