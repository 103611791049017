import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment as env } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { AppChallengeFormComponent } from './challenge-form.component';
import { ChallengeService, challengeStatus, creatorTypes } from 'src/app/services/challenge.service';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
})
export class AppChallengeComponent implements OnInit {

  constructor(private categoryService: CategoryService, private challengeService: ChallengeService, private http: HttpClient, public dialog: MatDialog) { }
  
  //status list
  statusList: any = Object.values(challengeStatus);

  //creator type list
  creatorTypeList: any = Object.values(creatorTypes);

  dataSource: any;
  categoryDataSource: any;
  selectedCreatorType: string = creatorTypes.king.value;
  selectedStatus: string[] = [
    challengeStatus.released.value,
    challengeStatus.finished.value,
    challengeStatus.waitingValidation.value,
    challengeStatus.waitingRelease.value,
  ];
  selectedCategoryId: string = '';
  perPage: number = 20;

  pagination: any = {
    offset: 0,
    currentPage: 0,
  };

  totalItems: number = 0;
  hasMore: boolean = true;

  ngOnInit(): void {
    this.categoryService.getCategories().subscribe((result: any) => {
      this.categoryDataSource = result.data.categories;
    });
    this.getChallenges();
  }

  createChallenge(challenge?: any) {
    this.dialog
      .open(AppChallengeFormComponent, {
        width: '60%',
        height: 'auto',
        data: {
          challenge: challenge,
          reason: 'create',
        },
      })
      .afterClosed()
      .subscribe((result: any) => {});
  }

  updateChallenge( challenge: any) {
    this.dialog
      .open(AppChallengeFormComponent, {
        width: '60%',
        height: 'auto',
        data: {
          challenge: challenge,
          reason: 'update',
        },
      })
      .afterClosed()
      .subscribe((result: any) => {});
  }

  getChallenges(append = false) {
    this.challengeService.getChallenge(
      this.selectedStatus,
      this.selectedCategoryId,
      "",
      this.pagination.offset,
      this.perPage,
      this.selectedCreatorType
    ).subscribe((result: any) => {
      if (append) {
        this.dataSource = this.dataSource.concat(result.data.challenges.items);
      } else {
        this.dataSource = result.data.challenges.items;
      }
      this.totalItems = result.data.challenges.pagination.total;
      this.hasMore = result.data.challenges.pagination.hasMore;
    });
  }

  //on scroll pagination
  loadMore() {
    if (!this.hasMore) {
      return;
    }
    this.pagination.currentPage += 1;
    this.pagination.offset = this.pagination.currentPage * this.perPage;
    this.getChallenges(true);
  }

  updateChallengeStatus(id: string, status: string) {
    console.log('status', status);
    return this.http
      .patch<any>(
        `${env.restUrl}/challenges/${id}`,
        {
          status: status,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token') + '',
          },
        }
      )
      .subscribe({
        next: (result) => {
          Swal.fire(
            'Success!',
            'Challenge status updated successfully',
            'success'
          );
          this.getChallenges();
        },
        error: (error) => {
          Swal.fire(
            'Error!',
            error.error.message ?? error.error.errors[0],
            'error'
          );
        },
      });
  }

  filterChallenges(form: NgForm) {
    if (form.value.status) {
      this.selectedStatus = form.value.status;
    }
    if (form.value.creatorType) {
      this.selectedCreatorType = form.value.creatorType;
    }
    if (form.value.categoryId) {
      this.selectedCategoryId = form.value.categoryId;
    }

    this.pagination = {
      offset: 0,
      currentPage: 0,
    };

    this.getChallenges();
  }

  filterOnchangeCategory(selectedCategoryId: string) {
    if (this.selectedCategoryId === selectedCategoryId) {
      this.selectedCategoryId = '';
    } else {
      this.selectedCategoryId = selectedCategoryId;
    }

    this.pagination = {
      offset: 0,
      currentPage: 0,
    };
    
    this.getChallenges();
  }

  fullScreenMedia(media: string) {
    //get screen width
    let screenheight = window.innerHeight - 100;

    Swal.fire({
      heightAuto: false,
      confirmButtonColor: '#D8315B',
      customClass:
      {
        popup: 'bb-video-previous-container',
      },
      html: `<video controls autoplay loop class="rounded" style="height: ${screenheight}px;">
                <source src="${media}" type="video/mp4">
                Your browser does not support the video tag.
            </video>`,
    });
  }

  //calculate the number of days between two dates
  calculateDays(startDate: string, endDate: string) {
    let date1: any = new Date(startDate);
    let date2: any = new Date(endDate);
    let diffDays: any = Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  //Veridier si le challenge est en cours, terminé ou en attente avec les dates
  checkChallengeStatusText(startDate: string, endDate: string, status: string) {
    let currentDate: any = new Date();
    let date1: any = new Date(startDate);
    let date2: any = new Date(endDate);
    if (date1 > currentDate) {
      let diffDays: any = Math.ceil((date1 - currentDate) / (1000 * 60 * 60 * 24));
      return "Le challenge commence dans " + diffDays + " jours 🚀";
    } else if (date1 <= currentDate && date2 >= currentDate) {
      let diffDays: any = Math.ceil((date2 - currentDate) / (1000 * 60 * 60 * 24));
      return "Le challenge se termine dans " + diffDays + " jours ⌛";
    } else if (date2 < currentDate) {
      return "Le challenge est terminé 🎉";
    } else {
      return status;
    }
  }

}
