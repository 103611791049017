import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_PROPOSITIONS } from '../graphql/proposition/queries';
import { PROCESS_PROPOSITION } from '../graphql/proposition/mutations';

export const propositionStatus = {
  pending: { value: "pending", label: "En attente"},
  accepted: { value: "accepted", label: "Accepté"},
  rejected: { value: "rejected", label: "Refusé"},
  closed: { value: "closed", label: "Fermé"},
}

@Injectable({
  providedIn: 'root'
})
export class PropositionService {

  constructor(private apollo: Apollo) { }

  getPropositions(status: string = propositionStatus.pending.value) {
    return this.apollo.query({
      query: GET_PROPOSITIONS,
      variables: {
        status: status,
      }
    })
  }

  processProposition(id: string, status: string) {
    return this.apollo.mutate({
      mutation: PROCESS_PROPOSITION,
      variables: {
        id: id,
        status: status,
      }
    })
  }

}
