import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { GET_CATEGORIES } from '../graphql/category/queries';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private apollo: Apollo) { }

  getCategories() {
    return this.apollo.query({
      query: GET_CATEGORIES,
    });
  }
}
