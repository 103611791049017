import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReportService, reportStatus, banDuration } from 'src/app/services/report.service';
import Swal from 'sweetalert2';
import { AppBanComponent } from './ban.component';
import { MatDialog } from '@angular/material/dialog';
import { AppChallengeComponent } from '../challenge/challenge.component';
import { AppWarnComponent } from './warn.component';

@Component({
    selector: 'report-component',
    templateUrl: './report.component.html',
    providers: [AppChallengeComponent, AppBanComponent, AppWarnComponent],
})
export class AppReportComponent implements OnInit {

    constructor(private snackBar: MatSnackBar, private reportService: ReportService, private http: HttpClient, public dialog: MatDialog, public AppChallengeComponent: AppChallengeComponent) {
        this.snackBar = snackBar;
    }

    displayedColumns: string[] = [
        'id',
        'information',
        'reporter',
        'action',
    ];

    //status list
    statusList: any = Object.values(reportStatus);
    reportDuration: any = Object.values(banDuration);

    reportDataSource: any;
    reportReasons: any;
    selectedStatus: string = reportStatus.pending.value;

    ngOnInit(): void {
        this.getReports();
        this.getReportReasons();
    }

    filterReports(status: string) {
        this.selectedStatus = status;
        this.getReports();
    }

    getReports() {
        this.reportService.getReports(this.selectedStatus).subscribe((result: any) => {
            this.reportDataSource = result.data.reports;
        });
    }

    getReportReasons() {
        this.reportService.getReportReasons().subscribe((result: any) => {
            this.reportReasons = result.data.reportReasons;
        });
    }

    closeReport(report: any) {
        Swal.fire({
            heightAuto: false,
            title: 'Fermer le signalement',
            text: 'Voulez-vous vraiment fermer les signalements concernant ce contenu et avertir l\'utilisateur ?',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler',
            confirmButtonColor: '#D8315B',
            cancelButtonColor: '#007bff',
        }).then((result) => {
            if (result.isConfirmed) {
                this.reportService.closeReport(report.id).subscribe((result: any) => {
                    this.snackBar.open('Signalement fermé !', 'Close', {
                        duration: 2000,
                    });
                    this.getReports();
                });
            }
        });
    }

    warnUserPopup(typeOfElement: string = "", elementId: string = "", user: any, reason: any = "") {
        this.dialog
            .open(AppWarnComponent, {
                width: 'auto',
                data: {
                    typeOfElement: typeOfElement,
                    reason: reason,
                    elementId: elementId,
                    user: user
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
            });
    }

    banUserPopup(user: any, reason: any = "") {
        this.dialog
            .open(AppBanComponent, {
                width: 'auto',
                data: {
                    reason: reason,
                    user: user
                },
            })
            .afterClosed()
            .subscribe((result: any) => {
            });
    }

    findReportReason(reasonKey: String) {
        return this.reportReasons.find((reason: any) => reason.key === reasonKey);
    }


    typeOfReport(report: any) {
        if (report.reportedChallenge) {
            return 'challenge';
        } else if (report.reportedParticipation) {
            return 'participation';
        } else if (report.reportedUser) {
            return 'user';
        } else {
            return 'unknown';
        }
    }

    getUserToban(report: any) {
        if (report.reportedChallenge) {
            return report.reportedChallenge.creator;
        } else if (report.reportedParticipation) {
            return report.reportedParticipation.user;
        } else if (report.reportedUser) {
            return report.reportedUser;
        } else {
            return null;
        }
    }

}
