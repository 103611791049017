<!-- ============================================================== -->
<!-- Simple four boxes Row -->
<!-- ============================================================== -->

<div class="col-lg-12">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-24">
      <div class="d-flex justify-content-between align-items-center m-b-16">

        <div class="d-flex align-items-center">
          <h2 class="m-0 mat-headline-2 f-w-600">Challenges</h2>
          <span class="m-l-16 mat-body-1 f-s-12">Liste des challenges</span>
        </div>

        <button mat-flat-button color="primary" (click)="createChallenge()">
          <mat-icon>add</mat-icon>
          <span class="">Ajouter un challenge</span>
        </button>

      </div>

      <!-- select of challenge creator type -->

      <form class="d-flex" #filterChallengesForm="ngForm" (ngSubmit)="filterChallenges(filterChallengesForm)">

        <mat-form-field appearance="outline" class="m-l-16">
          <mat-label>Type de créateur</mat-label>
          <mat-select [(ngModel)]="selectedCreatorType" matInput ngModel name="creator">
            <mat-option *ngFor="let creator of creatorTypeList" [value]="creator.value">{{creator.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="m-l-16">
          <mat-label>Statut</mat-label>
          <mat-select [(ngModel)]="selectedStatus" matInput ngModel name="status" multiple="true">
            <mat-option *ngFor="let status of statusList" [value]="status.value">{{status.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="m-l-16">
          <mat-label>Categorie</mat-label>
          <mat-select [(ngModel)]="selectedCategoryId" matInput ngModel name="categoryId">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let category of categoryDataSource" [value]="category.id">{{category.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-flat-button color="primary" type="submit" class="m-l-16">
          <mat-icon>search</mat-icon>
          <span class="">Filter</span>
        </button>

      </form>

      <mat-chip-listbox aria-label="Dog selection">
        <mat-chip-option class="f-s-14" color="primary" *ngFor="let category of categoryDataSource"
          [value]="category.id" (click)="filterOnchangeCategory(category.id)">
          {{category.icon}} {{category.name}}
        </mat-chip-option>
      </mat-chip-listbox>


    </mat-card-content>

    <div class="d-flex justify-content-center align-items-center m-b-16">
      <button mat-flat-button color="primary" (click)="loadMore()" *ngIf="hasMore">
        <mat-icon>arrow_downward</mat-icon>
        <span class="m-l-8">Charger plus de challenge</span>
      </button>
    </div>

  </mat-card>
</div>

<div class="d-flex flex-column align-content-start m-10">

  <challenge-card *ngFor="let challenge of dataSource" [challenge]="challenge"></challenge-card>

</div>