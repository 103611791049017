<!--Mettre dans un composant-->
<mat-card class="cardWithShadow col">
  <mat-card-content class="p-24">

    <div class="d-flex justify-content-between align-items-center">

      <div class="">

        <div class="f-s-16 f-w-600 m-b-10">
          <div class="f-s-10 m-b-10">
            ID: {{ user.uuid }}
          </div>
          <div class="d-flex flex-row align-items-center">

            <img *ngIf="user.avatarUrl != null && user.avatarUrl != ''" [src]="user.avatarUrl" width="80" height="80"
              class="rounded" />
            <img *ngIf="user.avatarUrl == null || user.avatarUrl == ''" src="/assets/images/profile/user-1.jpg"
              width="80" height="80" class="rounded" />
            <div class="m-l-16 flex-column">
              <h6 class="mat-subtitle-1 f-s-14 f-w-600">
                <span class=" text-primary">{{ user.accountName }}</span> ({{ "@" +
                user.username
                }})
              </h6>
              <div class="mat-subtitle-1 f-s-12 f-w-600 d-flex">
                {{ user.rank.name }} - {{ user.trophies }}
                <mat-icon class="d-flex align-items-center">
                  <i-tabler name="trophy" class="icon-16 d-flex"></i-tabler>
                </mat-icon>
              </div>
              <div class="mat-body-1">
                {{ user.email }}
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center">

          <button mat-flat-button color="primary" class="w-100 m-b-10" *ngIf="!user.isBanned"  (click)="banUserPopup(user)">
            <mat-icon>warning</mat-icon> Bannir
          </button>

          <hr class="m-10" *ngIf="!user.isBanned" />

          <button mat-flat-button color="accent" class="w-100 m-b-10" *ngIf="user.isBanned" (click)="unbanUser(user.uuid)">
            <mat-icon>check</mat-icon> Débloquer
          </button>
          
        </div>

      </div>

      <div class="d-flex flex-column justify-content-between align-items-center">
        <div class="d-flex flex-column align-items-start justify-content-start">
          <div class="f-s-16 f-w-600 text-accent">Statistiques</div>
          <div class="d-flex flex-row align-items-start justify-content-start">
            <div class="d-flex flex-column align-items-start m-10">
              <div class="f-s-14 f-w-600">Abonnés</div>
              <div class="f-s-12">{{ user.nbFollowedBy }}</div>
            </div>
            <div class="d-flex flex-column align-items-start m-10">
              <div class="f-s-14 f-w-600">Participations</div>
              <div class="f-s-12">{{ user.nbParticipations }}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </mat-card-content>
</mat-card>