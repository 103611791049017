import { Component, Input } from '@angular/core';
import { AppChallengeComponent } from './challenge.component';

@Component({
    selector: 'challenge-card',
    templateUrl: './challenge-card.component.html',
    providers: [AppChallengeComponent],
})
export class ChallengeCardComponent {
    @Input() challenge: any;

    constructor(
        public AppChallengeComponent: AppChallengeComponent
    ) {
        this.AppChallengeComponent = AppChallengeComponent;
    }

    ngOnInit(): void {}
}