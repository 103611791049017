import { gql } from '@apollo/client/core';
import { ADMIN_BASE_FIELDS } from './fragments';

export const CREATE_ADMIN = gql`
    mutation createAdmin($admin: CreateAdminInput!) {
        createAdmin(
            admin: $admin
        ) {
            ...adminBaseFields
        }
    }
    ${ADMIN_BASE_FIELDS}
`;

export const UPDATE_ADMIN = gql`
    mutation updateAdmin($infos: UpdateAdminInput!) {
        updateAdmin(
            infos: $infos
        ) {
            ...adminBaseFields
        }
    }
    ${ADMIN_BASE_FIELDS}
`;

export const ADMIN_SIGNIN = gql`
    mutation adminSignin($email: String!, $password: String!) {
        adminSignin(
            infos: {
                email: $email,
                password: $password
            }
        ) {
            token
            firstConnexion
            userInfos {
                ...on Admin {
                    ...adminBaseFields
                }
            }
        }
    }
    ${ADMIN_BASE_FIELDS}
`;