import { gql } from '@apollo/client/core';
import { PARTICIPATION_BASE_FIELDS } from '../participation/fragments';

export const REPORT_FIELDS = gql`
    fragment reportFields on Report {
        id
        context
        date
        status
        reason
        reporter {
            ...userBaseFields
        }
        reportedUser {
            ...userBaseFields
        }
        reportedChallenge {
            ...challengeBaseFields
            creator {
                uuid
                username
                avatarUrl
                rank {
                    id
                    name
                    label
                }
            }
        }
        reportedParticipation {
            ...participationBaseFields
        }
        reportedComment {
            id
            comment
            date
            challenge {
                ...challengeBaseFields
            }
            participation {
                ...participationBaseFields
            }
            user {
                ...userBaseFields
            }
        }
    }
    ${PARTICIPATION_BASE_FIELDS}
`;