import { Component } from '@angular/core';

@Component({
  selector: 'app-branding',
  template: `
    <div class="branding">
      <!-- crée moi une row -->
      <div class="row">
        <div class="col-2">
          <a href="/">
            <img
              src="./assets/images/logos/app_icon.png" width="25px" height="25px"
              class="align-middle m-2"
              alt="logo"
            />
          </a>
        </div>
        <div class="col ">
          <h1 class="m-2">BattleBox</h1>
        </div>
      </div>
    </div>
  `,
})
export class BrandingComponent {
  constructor() { }
}
